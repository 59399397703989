<!-- eslint-disable vue/no-unused-vars -->
<template>
  <Layout :items="items">
    <SplitHeader :left-size="6">
      <template #left>
        <div
          class="d-flex justify-content-between w-100 my-auto"
          style="height: 40px !important;"
        >
          <div class="d-flex">
            <h4 class="my-auto">
              FLEET CARBON EMISSION REPORT
            </h4>
          </div>
          <div style="width: 35%;">
            <FormInputSelect
              v-model="canBusAvailable"
              :options="fleetCanBusOptions"
              label="CAN Bus"
            />
          </div>
        </div>
      </template>
      <template #right>
        <div
          class="d-flex justify-content-between gap-2 w-100 my-auto"
          style="height: 40px !important;"
        >
          <b-col>
            <EmissionFilterFleet
              :can-bus-available="canBusAvailable"
              @filter="(val) => filter.device_ids = val"
            />
          </b-col>
          <b-col>
            <FormInputSelect
              v-model="filter.interval"
              :options="timeSeriesOptions"
              label="Time Series"
              :has-icon="true"
            />
          </b-col>
          <b-col>
            <FormDatePickerRange
              v-model="filter.date"
              :mode="filter.interval"
              :enable-time="false"
              :alt-format="datePickerFormat"
            />
          </b-col>
          <div>
            <b-button
              type="submit"
              variant="primary"
              @click="getData"
            >
              Check
            </b-button>
          </div>
          <div>
            <DownloadDataButton
              button-variant="outline-info"
              :data="csvData"
              :file-name="csvFileName"
            >
              <template #button-content>
                <i class="bx bx-download" />
              </template>
            </DownloadDataButton>
          </div>
        </div>
      </template>
    </SplitHeader>
    <b-row>
      <b-col
        style="margin-top: 2.9rem;"
        lg
        class="px-0 px-lg-2"
      >
        <b-row>
          <b-col lg="3">
            <b-card class="mb-0 h-50">
              <template #header>
                <div class="text-center fw-bold">
                  Total Carbon Emission
                </div>
              </template>
              <div class="d-flex h-100">
                <div class="text-center m-auto">
                  <div class="fw-bold fs-18">
                    {{ totalEmission }}
                  </div>
                  <div class="fs-14 mt-1">
                    kgCO2
                  </div>
                </div>
              </div>
            </b-card>
            <b-card class="mb-0 h-50">
              <template #header>
                <div class="text-center fw-bold">
                  Total Fuel Consumption
                </div>
              </template>
              <div class="d-flex h-100">
                <div class="text-center m-auto">
                  <div class="fw-bold fs-18">
                    {{ totalFuel }}
                  </div>
                  <div class="fs-14 mt-1">
                    liter
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col lg="9">
            <b-card class="mb-0">
              <template #header>
                <div class="text-center fw-bold">
                  Carbon Emission
                </div>
              </template>
              <div>
                <BarChart
                  style="max-height: 350px;"
                  :data="chartData"
                  y-label="kgCO2"
                />
              </div>
            </b-card>
          </b-col>
        </b-row>
        <hr>
        <div>
          <DataTable
            :fields="tableFields"
            :items="tableData"
            :is-loading="isLoading"
            :has-unit="true"
          >
            <template #header(total_operation_distance)="{ row }">
              <div>
                <div class="d-flex w-100">
                  <div class="me-3">
                    {{ row.item.label }}
                  </div>
                  <Popper
                    :hover="true"
                    :arrow="true"
                    class="border-0 m-auto"
                    placement="top"
                  >
                    <template #content>
                      <slot name="popper-content">
                        <div class="text-center">
                          The system calculates <br>
                          the distance traveled <br>
                          by each operating fleet
                        </div>
                      </slot>
                    </template>
                    <span class="fs-16">
                      <i class="bx bx-info-circle" />
                    </span>
                  </Popper>
                </div>
                <div class="fw-normal">
                  <small>
                    {{ row.item.unit }}
                  </small>
                </div>
              </div>
            </template>
            <template #header(total_fuel_consumption)="{ row }">
              <div>
                <div class="d-flex w-100">
                  <div class="me-3">
                    {{ row.item.label }}
                  </div>
                  <Popper
                    :hover="true"
                    :arrow="true"
                    class="border-0 m-auto"
                    placement="top"
                  >
                    <template #content>
                      <slot name="popper-content">
                        <div
                          v-if="canBusAvailable == 1" 
                          class="text-center"
                        >
                          Totaling CAN Bus parameters <br> 
                          which are Engine Total Fuel Used <br>
                          and Total Idle Fuel Used
                        </div>
                        <div
                          v-else 
                          class="text-center"
                        >
                          Divide the Total Operation <br>
                          Distance by the Fuel Ratio
                        </div>
                      </slot>
                    </template>
                    <span class="fs-16">
                      <i class="bx bx-info-circle" />
                    </span>
                  </Popper>
                </div>
                <div class="fw-normal">
                  <small>
                    {{ row.item.unit }}
                  </small>
                </div>
              </div>
            </template>
            <template #header(total_carbon_emission)="{ row }">
              <div>
                <div class="d-flex w-100">
                  <div class="me-3">
                    {{ row.item.label }}
                  </div>
                  <Popper
                    :hover="true"
                    :arrow="true"
                    class="border-0 m-auto"
                    placement="top"
                  >
                    <template #content>
                      <slot name="popper-content">
                        <div class="text-center">
                          Converting Total Fuel <br>
                          Consumption using the Carbon <br>
                          Emission Coefficient (kgCO2/L) <br>
                        </div>
                      </slot>
                    </template>
                    <span class="fs-16">
                      <i class="bx bx-info-circle" />
                    </span>
                  </Popper>
                </div>
                <div class="fw-normal">
                  <small>
                    {{ row.item.unit }}
                  </small>
                </div>
              </div>
            </template>
            <template #cell(logo)="{ row }">
              <div class="text-center">
                <img
                  :src="getFleetBrandIcon(row.item.brand)"
                  alt=""
                  style="max-height: 20px; max-width: 28px;"
                >
              </div>
            </template>
            <template #cell(vehicle_number)="{ row }">
              <router-link
                :to="`/dashboard?fleet=${row.item.id}`"
                class="link-item-table custom-link"
              >
                {{ row.item.vehicle_number }}
              </router-link>
            </template>
            <template #footer>
              <tr class="bg-light fw-bold ">
                <td
                  class="text-center"
                  colspan="4"
                >
                  Total
                </td>
                <td>
                  {{ canBusAvailable ? (totalFuel - totalIdleFuel).toFixed(2) : totalDistance }}
                </td>
                <td>
                  {{ totalIdleFuel }}
                </td>
                <td>
                  {{ totalFuel }}
                </td>
                <td>
                  -
                </td>
                <td>
                  {{ totalEmission }}
                </td>
              </tr>
            </template>
          </DataTable>
        </div>
      </b-col>
    </b-row>
  </Layout>
</template>

<script>
  import Layout from "@/layouts/main.vue";

  import SplitHeader from '@/components/SplitHeader.vue';
  import FormInputSelect from '@/components/FormInputSelect.vue';
  import FormDatePickerRange from '@/components/FormDatePickerRange.vue';
  import BarChart from '@/components/chart/BarChart.vue';

  import DataTable from '@/components/DataTable.vue';
  import DownloadDataButton from '@/components/DownloadDataButton.vue';

  import EmissionFilterFleet from '@/components/fleet/EmissionFilterFleet.vue';
  
  import { format, addDays, startOfMonth, endOfMonth } from 'date-fns';
  import { getFleetBrandIcon, highlightString } from '@/utils/helper';  
  import { fleetCanBusOptions } from '@/utils/constant';

  export default {
    components: {
      Layout,
      SplitHeader,
      FormInputSelect,
      FormDatePickerRange,
      BarChart,
      DataTable,
      EmissionFilterFleet,
      DownloadDataButton
    },
    data() {
      return {
        fleetCanBusOptions,
        items: [{
            text: "Fleet Management",
            href: "/fleet",
          },
        ],
        
        typeOptions: [
          { label: 'Smart Fleet', value: "smart_fleet" },
          { label: 'Non Smart Fleet', value: "non_smart_fleet" },
        ],
        timeSeriesOptions: [
          { label: 'Day', value: "daily" },
          { label: 'Month', value: "monthly" },
          { label: 'Year', value: "yearly" },
        ],

        isLoading: false,
        emissionData: [],
        emissionMeta: {},

        canBusAvailable: '1',
        fleetList: [],

        filter: {
          date: [
            new Date(new Date(addDays(new Date(), -7)).setHours(0, 0, 0)),
            new Date(new Date().setHours(23, 59, 59))],
          interval: 'daily',
          device_ids: []
        },
      };
    },
    computed: {
      selectedOrganization() {
        return this.$store.state.organization.selectedOrganization;
      },
      processedFilter() {    
        let tzoffset = (new Date()).getTimezoneOffset() * -60000; //offset in milliseconds
    
        let start_date = this.filter.date[0] ? new Date(new Date(this.filter.date[0]) - tzoffset).toISOString() : ''
        let end_date = this.filter.date[1] ? new Date(new Date(this.filter.date[1]) - tzoffset).toISOString() : ''
        return {
          start_date: start_date,
          end_date: end_date,
          interval: this.filter.interval,
          per_page: 200,
          page: 1,
          payload_keys: this.canBusAvailable == '1' ? ['can_bus.engine_total_fuel_used', 'can_bus.total_idle_fuel_used'] : ['location'],
          device_ids: this.filter.device_ids,
          aggregate: 'sum',
          use_device_coefficient: 1
        }
      },

      chartData() {
        if(this.emissionData.length) {
          if (this.emissionMeta?.interval == 'yearly') {
            return this.emissionData.map((item)=> {
              const date = new Date(item.started_at)
              return {
                label: [format(date, 'yyyy')],
                value: item.cumulative_value ?? 0
              }
            }).reverse()
          } else if (this.emissionMeta?.interval == 'monthly') {
            return this.emissionData.map((item)=> {
              const date = new Date(item.started_at)
              return {
                label: [format(date, 'MMM'), format(date, 'yyyy')],
                value: item.cumulative_value ?? 0
              }
            }).reverse()
          } else {
            return this.emissionData.map((item)=> {
              const date = new Date(item.started_at)
              return {
                label: [format(date, 'dd'), format(date, 'MMM'), format(date, 'yy')],
                value: item.cumulative_value ?? 0
              }
            }).reverse()
          }
        }
        return []
      },

      datePickerFormat() {
        if(this.filter.interval == 'monthly') {
          return 'M Y'
        } else {
          return "j M Y"
        }
      },

      totalIdleFuel() {
        let total = 0
        this.tableData.forEach(item => total += Number(item.total_idle_fuel_used ?? 0))
        return total.toFixed(2)
      },
      totalFuel() {
        let total = 0
        this.tableData.forEach(item => total += Number(item.total_fuel_consumption ?? 0))
        return total.toFixed(2)
      },
      totalEmission() {
        let total = 0
        this.tableData.forEach(item => total += Number(item.total_carbon_emission ?? 0))
        return total.toFixed(2)
      },

      totalDistance() {
        let total = 0
        this.tableData.forEach(item => total += Number(item.total_operation_distance ?? 0))
        return total.toFixed(2)
      },

      tableFields() {
        const fields = [{
            key: 'logo',
            label: '',
            unit: ' '
          }, {
            key: 'vehicle_number',
            label: 'Vehicle Number',
            unit: 'No. Polisi',
            sortable: true,
          }, {
            key: 'fleet_type',
            label: 'Type',
            thStyle: {
              minWidth: '4rem',
            },
            sortable: true,
          }, {
            key: 'driver_name',
            label: 'Driver',
            unit: 'Name',
            class: 'fit',
            thStyle: {
              minWidth: '8rem',
            },
            sortable: true,
        }]
        const canBusFields  = [{
            key: 'engine_total_fuel_used',
            label: 'Engine Total Fuel Used',
            unit: 'L',
            sortable: true,
          }, {
            key: 'total_idle_fuel_used',
            label: 'Total Idle Fuel Used',
            unit: 'L',
            sortable: true,
          }, {
            key: 'total_fuel_consumption',
            label: 'Total Fuel Consumption',
            unit: 'L',
            sortable: true,
          }, {
            key: 'emission_coefficient',
            label: 'Coefficient',
            unit: 'kgCO2/L',
          }, {
            key: 'total_carbon_emission',
            label: 'Total Carbon Emission',
            unit: 'kgCO2',
            sortable: true,
        }]
        const nonCanBusFields = [{
            key: 'total_operation_distance',
            label: 'Total Operation Distance',
            unit: 'km',
            sortable: true,
          }, {
            key: 'fuel_ratio',
            label: 'Fuel Ratio',
            unit: 'km/L',
          }, {
            key: 'total_fuel_consumption',
            label: 'Total Fuel Consumption',
            unit: 'L',
            sortable: true,
          }, {
            key: 'emission_coefficient',
            label: 'Coefficient',
            unit: 'kgCO2/L',
          }, {
            key: 'total_carbon_emission',
            label: 'Total Carbon Emission',
            unit: 'kgCO2',
            sortable: true,
        }]
        if (this.canBusAvailable == '1') {
          return fields.concat(canBusFields)
        } return fields.concat(nonCanBusFields)
      },

      tableData() {
        if (this.canBusAvailable == '1') {
          return this.fleetList.map(fleet => ({
            ...fleet,
            engine_total_fuel_used: Number(fleet.collections[`can_bus.engine_total_fuel_used`]).toFixed(2) ?? '-',
            total_idle_fuel_used: Number(fleet.collections[`can_bus.total_idle_fuel_used`]).toFixed(2) ?? '-',
            total_fuel_consumption: Number((fleet.collections[`can_bus.total_idle_fuel_used`] + fleet.collections[`can_bus.engine_total_fuel_used`])).toFixed(2) ?? '-',
            total_carbon_emission: Number(((fleet.collections[`can_bus.total_idle_fuel_used`] + fleet.collections[`can_bus.engine_total_fuel_used`]) * fleet.emission_coefficient)).toFixed(2) ?? '-'
          }))
        } 
        return this.fleetList.map(fleet => ({
          ...fleet,
          total_operation_distance: Number(fleet.collections[`location`]).toFixed(2) ?? '-',
          total_fuel_consumption: Number(fleet.collections[`location`] / fleet.fuel_ratio).toFixed(2) ?? '-',
          total_carbon_emission: Number(fleet.collections[`location`] / fleet.fuel_ratio * fleet.emission_coefficient).toFixed(2) ?? '-'
        }))
      },

      csvData() {
        if(this.canBusAvailable == '1') {
          const data = this.tableData.map((fleet) => (
            {
              'Vehicle Number' : fleet.vehicle_number,
              'Type' : fleet.fleet_type,
              'Driver' : fleet.driver_name,
              'Engine Total Fuel Used (L)' : fleet.engine_total_fuel_used,
              'Total Idle Fuel Used (L)' : fleet.total_idle_fuel_used,
              'Total Fuel Consumption (L)' : fleet.total_fuel_consumption,
              'Coefficient' : fleet.emission_coefficient,
              'Total Carbon Emission (kgCO2)' : fleet.total_carbon_emission
            }
          ))
          const total = {
            'Vehicle Number' : 'Total',
            'Type' : '',
            'Driver' : '',
            'Engine Total Fuel Used (L)' : (this.totalFuel - this.totalIdleFuel).toFixed(2),
            'Total Idle Fuel Used (L)' : this.totalIdleFuel,
            'Total Fuel Consumption (L)' : this.totalFuel,
            'Coefficient' : '-',
            'Total Carbon Emission (kgCO2)' : this.totalEmission
          }
          return data.concat(total)
        } else {
          const data = this.tableData.map((fleet) => (
            {
              'Vehicle Number' : fleet.vehicle_number,
              'Type' : fleet.fleet_type,
              'Driver' : fleet.driver_name,
              'Total Operation Distance (km)' : fleet.total_operation_distance,
              'Fuel Ratio (km/L)' : fleet.fuel_ratio,
              'Total Fuel Consumption (L)' : fleet.total_fuel_consumption,
              'Coefficient' : fleet.emission_coefficient,
              'Total Carbon Emission (kgCO2)' : fleet.total_carbon_emission
            }
          ))
          const total = {
            'Vehicle Number' : 'Total',
            'Type' : '',
            'Driver' : '',
            'Total Operation Distance (km)' : this.totalDistance,
            'Fuel Ratio (km/L)' : '-',
            'Total Fuel Consumption (L)' : this.totalFuel,
            'Coefficient' : '-',
            'Total Carbon Emission (kgCO2)' : this.totalEmission
          }
          return data.concat(total)
        }
      },
      csvFileName() {
        const start = this.filter.date[0] ? format(this.filter.date[0], 'yyyyMMdd') : ''
        const end = this.filter.date[1] ? format(this.filter.date[1], 'yyyyMMdd') : ''
        const interval = this.filter.interval
        return `Emission Report - ${interval} - ${start}_${end}`
      },
    },
    watch: {
      selectedOrganization: {
        immediate: true,
        handler(organizationId) {
          if (organizationId) {
            this.getData()
          }
        },
      },
      canBusAvailable: {
        immediate: false,
        handler() {
          setTimeout(() => {
            this.getData()
          }, 1);
        },
      },
      'filter.interval': {
        deep: true,
        immediate: false,
        handler(val) {
          if(val == 'daily') {
            // Daily set last 7 days
            this.filter.date = [ 
              new Date(new Date(addDays(new Date(), -7)).setHours(0, 0, 0)),
              new Date(new Date().setHours(23, 59, 59))
            ]
          } else if(val == 'monthly') {
            // Monthly set current month
            this.filter.date = [ 
              new Date(new Date(startOfMonth(new Date())).setHours(0, 0, 0)),
              new Date(new Date(endOfMonth(new Date())).setHours(23, 59, 59))
            ]
          }
        },
      },
    },
    methods: {
      highlightString, getFleetBrandIcon,
      getData() {
        this.getEmissionData()
        this.getFleetList()
      },
      async getEmissionData() {
        try {
          this.isLoading = true;
          const { data } = await this.$api.getOrganizationCollection(this.selectedOrganization, { ...this.processedFilter });
          this.emissionData = data.data;
          this.emissionMeta = data.meta;
          this.emissionMeta.interval = this.processedFilter.interval
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      },
      async getFleetList() {
        try {
          this.isLoading = true;
          const { data } = await this.$api.getOrganizationDeviceCollection(this.selectedOrganization, { ...this.processedFilter });
          this.fleetList = data.data;
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      },
    },
  };
</script>
<style scoped>
.icon-table{
  max-height: 20px; max-width: 28px;
}
</style>